<template>
	<div class="page-home" v-loading="loading">
		<div class="page-head">
			<router-link :to="{name: 'LiveInformationcard'}" class="btn-prev"><i class="el-icon-arrow-left"></i><span>{{pageTitle}}</span></router-link>
			<a href="javascript:void(0);" class="btn-del" @click="handleDeleteCard" v-if="$route.params.id>0"><i class="el-icon-delete"></i>{{$t('liveplatform.infoCard.deleteBtn')}}</a>
		</div>
		<div class="page-body">
			<div class="page-preview">
				<img src="@/assets/images/liveplatform/decoration-375-812.jpg" width="100%" alt="">
				<div class="page-canvas-hide">
					<div class="page-canvas" ref="cardCanvas">
						<div class="canvas-main" v-if="cardForm.group_type>0">
							<div class="canvas-title" v-if="cardForm.group_type!==4">
								<span>—</span>
								<span class="name"><i class="el-icon-s-order"></i>{{cardForm.group_name}}</span>
								<span>—</span>
							</div>
							<div class="canvas-img" v-if="cardForm.group_type==1 || cardForm.group_type==5">
								<img :src="cardForm.group_base64" alt="">
							</div>
							<template v-if="cardForm.group_type==1">
								<div class="canvas-text">{{cardForm.group_nick}}</div>
								<div class="canvas-text">{{cardForm.group_size}}</div>
							</template>
							<template v-else-if="cardForm.group_type==2">
								<div class="canvas-text" style="padding: 4px 0;">{{cardForm.group_text1}}</div>
								<div class="canvas-text" style="padding: 4px 0;">{{cardForm.group_text2}}</div>
								<div class="canvas-text" style="padding: 4px 0;">{{cardForm.group_text3}}</div>
							</template>
							<template v-else-if="cardForm.group_type==3">
								<div class="canvas-text font16" style="padding: 2px 0;">{{cardForm.group_time1}}</div>
								<div class="canvas-text" style="padding: 2px 0;">{{cardForm.group_text1}}</div>
								<div class="canvas-text font16" style="padding: 2px 0;">{{cardForm.group_time2}}</div>
								<div class="canvas-text" style="padding: 2px 0;">{{cardForm.group_text2}}</div>
							</template>
							<template v-else-if="cardForm.group_type==4">
								<img class="canvas-img2" :src="cardForm.group_base64" alt="">
							</template>
							<template v-else-if="cardForm.group_type==5">
							<div class="canvas-text font18">{{cardForm.group_benefit}}</div>
							<div class="canvas-text">{{cardForm.group_price}}</div>
							</template>
						</div>
					</div>
					<div class="page-canvas" v-for="(item,index) in groupList" :key="index" ref="groupCanvas">
						<div class="canvas-main">
							<div class="canvas-title" v-if="item.group_type!==4">
								<span>—</span>
								<span class="name"><i class="el-icon-s-order"></i>{{item.group_name}}</span>
								<span>—</span>
							</div>
							<div class="canvas-img" v-if="item.group_type==1 || item.group_type==5">
								<img :src="item.group_base64" alt="">
							</div>
							<template v-if="item.group_type==1">
								<div class="canvas-text">{{item.group_nick}}</div>
								<div class="canvas-text">{{item.group_size}}</div>
							</template>
							<template v-else-if="item.group_type==2">
								<div class="canvas-text" style="padding: 4px 0;">{{item.group_text1}}</div>
								<div class="canvas-text" style="padding: 4px 0;">{{item.group_text2}}</div>
								<div class="canvas-text" style="padding: 4px 0;">{{item.group_text3}}</div>
							</template>
							<template v-else-if="item.group_type==3">
								<div class="canvas-text font16" style="padding: 2px 0;">{{item.group_time1}}</div>
								<div class="canvas-text" style="padding: 2px 0;">{{item.group_text1}}</div>
								<div class="canvas-text font16" style="padding: 2px 0;">{{item.group_time2}}</div>
								<div class="canvas-text" style="padding: 2px 0;">{{item.group_text2}}</div>
							</template>
							<template v-else-if="item.group_type==4">
								<img class="canvas-img2" :src="item.group_base64" alt="">
							</template>
							<template v-else-if="item.group_type==5">
							<div class="canvas-text font18">{{item.group_benefit}}</div>
							<div class="canvas-text">{{item.group_price}}</div>
							</template>
						</div>
					</div>
					<div class="page-canvas-view" ref="cardCanvasView">
						<template v-for="(item,index) in cardGroup">
							<div class="item" :key="index" v-if="item.group_cover"><img :src="item.group_cover" alt=""></div>
						</template>
					</div>
				</div>
				<div class="preview-inner">
					<div class="preview-wrap" v-if="tabsActive==1">
						<div class="item" v-for="(item,index) in cardGroup" :key="index" :class="cardType==index?'active':''" @click="handleCardChange(index)">
							<img v-if="item.group_cover" :src="item.group_cover" alt="">
							<i class="el-icon-plus" v-else></i>
						</div>
					</div>
					<div class="preview-wrap" v-else>
						<div class="item h280">
							<template v-if="cardForm.cover">
								<img :src="cardForm.cover" alt="">
							</template>
							<template v-else>
								<i class="el-icon-plus"></i>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div class="page-content">
				<el-tabs v-model="tabsActive" @tab-click="handleClickCard">
					<el-tab-pane :label="$t('liveplatform.infoCard.paneName1')" name="1">
						<el-form :model="cardForm" ref="cardForm1" label-width="100px" size="small">
							<el-form-item :label="$t('liveplatform.infoCard.formItem1')" prop="name" :rules="{ required: true, message: $t('liveplatform.validate.cardName'), trigger: 'blur' }">
								<el-input v-model="cardForm.name" :placeholder="$t('liveplatform.placeholder.cardName')" maxlength="8"></el-input>
							</el-form-item>
							<el-form-item :label="$t('liveplatform.infoCard.formItem2')" prop="group_type" :rules="{ required: true, message: $t('liveplatform.validate.cardModule'), trigger: 'blur' }" label-width="100%">
							</el-form-item>
							<el-form-item label-width="0px">
								<div class="template-list">
									<div class="item" :class="cardForm.group_type==1?'active':''" @click="handleGroupChange(1)">
										<div class="pic"><img src="@/assets/images/liveplatform/decoration-img1.png" ></div>
										<div class="name">{{$t('liveplatform.infoCard.groupItem1')}}</div>
									</div>
									<div class="item" :class="cardForm.group_type==2?'active':''" @click="handleGroupChange(2)">
										<div class="pic"><img src="@/assets/images/liveplatform/decoration-img2.png" alt=""></div>
										<div class="name">{{$t('liveplatform.infoCard.groupItem2')}}</div>
									</div>
									<div class="item" :class="cardForm.group_type==3?'active':''" @click="handleGroupChange(3)">
										<div class="pic"><img src="@/assets/images/liveplatform/decoration-img3.png" alt=""></div>
										<div class="name">{{$t('liveplatform.infoCard.groupItem3')}}</div>
									</div>
									<div class="item" :class="cardForm.group_type==4?'active':''" @click="handleGroupChange(4)">
										<div class="pic"><img src="@/assets/images/liveplatform/decoration-img4.png" alt=""></div>
										<div class="name">{{$t('liveplatform.infoCard.groupItem4')}}</div>
									</div>
									<div class="item" :class="cardForm.group_type==5?'active':''" @click="handleGroupChange(5)">
										<div class="pic"><img src="@/assets/images/liveplatform/decoration-img5.png" alt=""></div>
										<div class="name">{{$t('liveplatform.infoCard.groupItem5')}}</div>
									</div>
								</div>
							</el-form-item>
							<div class="page-main" v-if="cardForm.group_type>0">
								<!-- 主播信息 -->
								<template v-if="cardForm.group_type == 1">
									<el-form-item :label="$t('liveplatform.infoCard.formItem3')" prop="group_name" :rules="{ required: true, validator: validateName, trigger: 'blur' }">
										<el-input v-model="cardForm.group_name" maxlength="8"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem4')" prop="group_image" :rules="{ required: true, message: $t('liveplatform.validate.cardAvatar'), trigger: 'blur' }">
										<el-upload
											action="#"
											class="card-uplaod"
											:show-file-list="false"
											accept="image/*"
											:http-request="(data)=>{httpRequestImage(data.file,375,375)}">
											<div class="upload-main">
												<template v-if="cardForm.group_image">
													<img :src="cardForm.group_image" alt="">
													<div class="upload-tip">{{$t('liveplatform.infoCard.reupload')}}</div>
												</template>
												<template v-else>
													<i class="el-icon-plus"></i>
													<div class="upload-tip">{{$t('liveplatform.infoCard.upload')}}</div>
												</template>
											</div>
										</el-upload>
										<div class="page-notice">{{$t('liveplatform.infoCard.formText4-1')}}</div>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem5')" prop="group_nick" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_nick"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem6')" prop="group_size" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_size"></el-input>
									</el-form-item>
								</template>
								<!-- 营销类信息 -->
								<template v-else-if="cardForm.group_type == 2">
									<el-form-item :label="$t('liveplatform.infoCard.formItem3')" prop="group_name" :rules="{ required: true, validator: validateName, trigger: 'blur' }">
										<el-input v-model="cardForm.group_name" :placeholder="$t('liveplatform.placeholder.cardGroupName')" maxlength="8"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem7')+'1'" prop="group_text1" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_text1" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem7')+'2'" prop="group_text2" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_text2" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem7')+'3'" prop="group_text3" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_text3" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
								</template>
								<!-- 预告信息 -->
								<template v-else-if="cardForm.group_type == 3">
									<el-form-item :label="$t('liveplatform.infoCard.formItem3')" prop="group_name" :rules="{ required: true, validator: validateName, trigger: 'blur' }">
										<el-input v-model="cardForm.group_name" :placeholder="$t('liveplatform.placeholder.cardGroupName')" maxlength="8"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem8')+'1'" prop="group_time1" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_time1" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem9')+'1'" prop="group_text1" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_text1" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem8')+'2'" prop="group_time2" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_time2" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem9')+'2'" prop="group_text2" :rules="{ required: true, message: $t('liveplatform.validate.cardNotNull'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_text2" :placeholder="$t('liveplatform.placeholder.cardGroupText')"></el-input>
									</el-form-item>
								</template>
								<!-- 营销素材 -->
								<template v-else-if="cardForm.group_type == 4">
									<el-form-item :label="$t('liveplatform.infoCard.formItem10')" prop="group_image" :rules="{ required: true, message: $t('liveplatform.validate.cardMaterial'), trigger: 'blur' }">
										<el-upload
											action="#"
											class="card-uplaod"
											:show-file-list="false"
											accept="image/*"
											:http-request="(data)=>{httpRequestImage(data.file,190,190)}">
											<div class="upload-main">
												<template v-if="cardForm.group_image">
													<img :src="cardForm.group_image" >
													<div class="upload-tip">{{$t('liveplatform.infoCard.reupload')}}</div>
												</template>
												<template v-else>
													<i class="el-icon-plus"></i>
													<div class="upload-tip">{{$t('liveplatform.infoCard.upload')}}</div>
												</template>
											</div>
										</el-upload>
										<div class="page-notice">{{$t('liveplatform.infoCard.formText10-1')}}</div>
									</el-form-item>
								</template>
								<!-- 镇店之宝 -->
								<template v-else-if="cardForm.group_type == 5">
									<el-form-item :label="$t('liveplatform.infoCard.formItem3')" prop="group_name" :rules="{ required: true, validator: validateName, trigger: 'blur' }">
										<el-input v-model="cardForm.group_name" maxlength="8"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem11')">
										<el-upload
											action="#"
											class="card-uplaod"
											:show-file-list="false"
											accept="image/*"
											:http-request="(data)=>{httpRequestImage(data.file,375,375)}">
											<div class="upload-main">
												<template v-if="cardForm.group_image">
													<img :src="cardForm.group_image" >
													<div class="upload-tip">{{$t('liveplatform.infoCard.reupload')}}</div>
												</template>
												<template v-else>
													<i class="el-icon-plus"></i>
													<div class="upload-tip">{{$t('liveplatform.infoCard.upload')}}</div>
												</template>
											</div>
										</el-upload>
										<div class="page-notice">{{$t('liveplatform.infoCard.formText11-1')}}</div>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem12')" prop="group_benefit" :rules="{ required: true, message: $t('liveplatform.validate.cardMaterial'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_benefit"></el-input>
									</el-form-item>
									<el-form-item :label="$t('liveplatform.infoCard.formItem13')" prop="group_price" :rules="{ required: true, message: $t('liveplatform.validate.cardMaterial'), trigger: 'blur' }">
										<el-input v-model="cardForm.group_price"></el-input>
									</el-form-item>
								</template>
								<el-form-item>
									<el-button type="infor" @click="handleGroupAdd" round>{{$t('liveplatform.infoCard.saveBtn')}}</el-button>
								</el-form-item>
							</div>
							<el-form-item>
								<div class="page-bottom">
									<el-button type="danger" @click="handleSubmitCard" round>{{$t('liveplatform.infoCard.submitBtn')}}</el-button>
								</div>
							</el-form-item>
						</el-form>
					</el-tab-pane>
					<el-tab-pane :label="$t('liveplatform.infoCard.paneName2')" name="2">
						<el-form :model="cardForm" ref="cardForm2" label-width="100px" size="small">
							<el-form-item :label="$t('liveplatform.infoCard.formItem1')" prop="name" :rules="{ required: true, message: $t('liveplatform.validate.cardName'), trigger: 'blur' }">
								<el-input v-model="cardForm.name" :placeholder="$t('liveplatform.placeholder.cardName')" maxlength="8"></el-input>
							</el-form-item>
							<el-form-item :label="$t('liveplatform.infoCard.formItem14')" prop="cover" :rules="{ required: true, message: $t('liveplatform.validate.cardCover'), trigger: 'blur' }">
								<el-upload
									action="#"
									class="long-upload"
									:show-file-list="false"
									accept="image/*"
									:http-request="(data)=>{httpRequestImage2(data.file)}">
									<div class="upload-main">
										<template v-if="cardForm.cover">
											<img :src="cardForm.cover" alt="">
											<div class="upload-tip">{{$t('liveplatform.infoCard.reupload')}}</div>
										</template>
										<template v-else>
											<i class="el-icon-plus"></i>
											<div class="upload-tip">{{$t('liveplatform.infoCard.upload')}}</div>
										</template>
									</div>
								</el-upload>
								<div class="page-notice">
									<p>{{$t('liveplatform.infoCard.formText14-1')}}</p>
									<p>{{$t('liveplatform.infoCard.formText14-2')}}</p>
								</div>
							</el-form-item>
							<el-form-item>
								<div class="page-bottom">
									<el-button type="danger" @click="handleSaveCard" round>{{$t('liveplatform.infoCard.saveBtn')}}</el-button>
								</div>
							</el-form-item>
						</el-form>
					</el-tab-pane>
				</el-tabs>
				<el-dialog
					:title="$t('liveplatform.infoCard.dialogTitle')"
					width="800px"
					:visible.sync="dialogVisibleCropper">
					<div class="dialog-cropper">
						<vueCropper
							ref="cropper"
							:img="option.img"
							:outputSize="option.size"
							:outputType="option.outputType"
							:info="true"
							:full="option.full"
							:canMove="option.canMove"
							:canMoveBox="option.canMoveBox"
							:original="option.original"
							:autoCrop="option.autoCrop"
							:autoCropWidth="option.autoCropWidth"
							:autoCropHeight="option.autoCropHeight"
							:fixed="option.fixed"
							:fixedNumber="option.fixedNumber"
							:centerBox="option.centerBox"
							:infoTrue="option.infoTrue"
							:fixedBox="option.fixedBox">
						</vueCropper>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button @click="handleCancelCropper" size="small" round>{{$t('liveplatform.infoCard.dialogCancel')}}</el-button>
						<el-button type="danger" @click="handleConfirmCropper" size="small" round v-loading="loadingUplaod">{{$t('liveplatform.infoCard.dialogConfirm')}}</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import html2canvas from 'html2canvas'
	import { fetchInfoCard, createInfoCard, updeteInfoCard, deleteInfoCard } from '@/api/liveplatform'
	import { normalOSSUpload } from '@/utils/upload';
	export default {
		data() {
			return {
				pageTitle: this.$t('liveplatform.infoCard.pageAddTitle'),
				cardInfo: {},
				cardGroup: [{
					group_type: 0,
					group_cover: '',
					group_list: [
						{group_name:'',group_image:'',group_nick:'',group_size:''},
						{group_name:'',group_text1:'',group_text2:'',group_text3:''},
						{group_name:'',group_time1:'',group_text1:'',group_time2:'',group_text2:''},
						{group_image:''},
						{group_name:'',group_image:'',group_goods: [],group_benefit:'',group_price:''},
					]
				},{
					group_type: 0,
					group_cover: '',
					group_list: [
						{group_name:'',group_image:'',group_nick:'',group_size:''},
						{group_name:'',group_text1:'',group_text2:'',group_text3:''},
						{group_name:'',group_time1:'',group_text1:'',group_time2:'',group_text2:''},
						{group_image:''},
						{group_name:'',group_image:'',group_goods: [],group_benefit:'',group_price:''},
					]
				},{
					group_type: 0,
					group_cover: '',
					group_list: [
						{group_name:'',group_image:'',group_nick:'',group_size:''},
						{group_name:'',group_text1:'',group_text2:'',group_text3:''},
						{group_name:'',group_time1:'',group_text1:'',group_time2:'',group_text2:''},
						{group_image:''},
						{group_name:'',group_image:'',group_goods: [],group_benefit:'',group_price:''},
					]
				}],
				groupList: [],
				tabsActive: '1',
				cardType: 0,
				cardCanvas: {},
				cardForm: {
					name: '',
					cover: '',
					group_type: 0,
				},
				dialogVisibleCropper: false,
				loadingUplaod: false,
				option: {
					img: '', // 裁剪图片的地址
					info: true, // 裁剪框的大小信息
					outputSize: 0.8, // 裁剪生成图片的质量
					outputType: 'jpeg', // 裁剪生成图片的格式
					canScale: false, // 图片是否允许滚轮缩放
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 375, // 默认生成截图框宽度
					autoCropHeight: 375, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					fixed: true, // 是否开启截图框宽高固定比例
					fixedNumber: [1, 1], // 截图框的宽高比例
					full: true, // 是否输出原图比例的截图
					canMoveBox: false, // 截图框能否拖动
					original: true, // 上传图片按照原始比例渲染
					centerBox: true, // 截图框是否被限制在图片里面
					infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				},
				fileInfo: {},
				cardFlag: true,
				loading: false,
			}
		},
		created() {
			if(this.$route.params.id>0){
				this.loading = true;
				this.cardFlag = false;
				this.getCardInfo();
				this.pageTitle = this.$t('liveplatform.infoCard.pageEditTitle');
			}
		},
		methods: {
			getCardInfo(){
				let param = {
					info_card_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				fetchInfoCard(param).then(response => {
					this.cardInfo = response.data;
					let content = JSON.parse(response.data.content);
					this.tabsActive = content.card_type;
					if(content.card_type==1){
						this.groupList = content.card_list?JSON.parse(JSON.stringify(content.card_list)):[];
						this.groupList.forEach((item,object)=>{
							if(item.group_image){
								this.onBase64Image(item.group_image).then((base64)=>{
									item.group_base64 = base64;
								})
							}
						})
						this.$nextTick(()=>{
							this.onHtml2canvas();
						})
					}else{
						this.cardForm.name = response.data.name;
						this.cardForm.cover = response.data.image_url;
						this.loading = false;
						this.cardFlag = true;
					}
				}).catch(() => {
					this.loading = false;
				})
			},
			onHtml2canvas(){
				if(!this.groupList.length>0){
					this.loading = false;
					return false;
				}
				var index = 0;
				var deep = (item)=>{
					this.$nextTick(()=>{
						html2canvas(this.$refs.groupCanvas[index], {
							useCORS: true,
							backgroundColor: null,
						}).then((canvas) => {
							let url = canvas.toDataURL("image/png");
							this.cardGroup[index].group_cover = url;
							this.cardGroup[index].group_type = item.group_type;
							let groupItem = JSON.parse(JSON.stringify(item));
							delete groupItem.group_type;
							this.cardGroup[index].group_list[item.group_type-1] = groupItem;
							index++;
							if(this.groupList[index]){
								deep(this.groupList[index]);
							}else {
								this.cardForm = {
									name: this.cardInfo.name,
									cover: this.cardInfo.image_url,
									...this.groupList[this.cardType]
								}
								this.cardFlag = true;
								this.loading = false;
							}
						})
					})
				}
				deep(this.groupList[index]);
			},
			onBase64Image(url){
				var image = new Image();
				var canvas = document.createElement('canvas');
				return new Promise((resolve,reject)=>{
					image.src = url + '?v=' + Math.random();
					image.crossOrigin = '*';
					image.onload = ()=> {
						canvas.width = image.width;
						canvas.height = image.height;
						var ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, image.width, image.height);
						var dataURL = canvas.toDataURL('image/png');
						resolve(dataURL);
					}
				})
			},
			strlen(str){
				var len = 0;
				for(let i=0;i<str.length;i++){
					var c= str.charCodeAt(i);
					if((c>=0x001 && c<=0x007e) || (0xff60<=c && c<=0xff9f)){
						len++;
					}else {
						len+=2;
					}
				}
				return len;
			},
			validateName(rule, value, callback){
				let leng = this.strlen(value);
				if(value === ''){
					callback(new Error(this.$t('liveplatform.validate.cardNotNull')));
				}else if(leng>8){
					callback(new Error(this.$t('liveplatform.placeholder.cardGroupName')));
				}else{
					callback();
				}
			},
			handleCardChange(index){
				if(this.groupList && this.groupList.length>=index){
					let groupData = JSON.parse(JSON.stringify(this.cardForm));
					delete groupData.name;
					delete groupData.cover;
					delete groupData.group_type;
					this.cardGroup[this.cardType].group_type = this.cardForm.group_type;
					this.cardGroup[this.cardType].group_list[this.cardForm.group_type-1] = groupData;
					let groupOptions = {
						group_type: this.cardForm.group_type,
						...groupData
					}
					this.groupList[this.cardType] = groupOptions;
					this.$nextTick(function(){
						this.cardType = index;
						let formParams = [];
						if(this.cardGroup[index].group_type>0){
							formParams = {
								group_type: this.cardGroup[index].group_type,
								...this.cardGroup[index].group_list[this.cardGroup[index].group_type-1],
							}
						}else{
							formParams.group_type = 0;
						}
						let formData = {
							name: this.cardForm.name,
							cover: this.cardForm.cover,
							...formParams,
						}
						this.cardForm = JSON.parse(JSON.stringify(formData));
					})
				}else{
					this.$message.error(this.$t('liveplatform.errorMsg.cardChange'));
				}
				// this.cardType = index;
			},
			handleClickCard(tab, event){},
			handleGroupChange(type){
				if(this.cardForm.group_type>0){
					let groupData = JSON.parse(JSON.stringify(this.cardForm));
					delete groupData.name;
					delete groupData.cover;
					delete groupData.group_type;
					this.cardGroup[this.cardType].group_list[this.cardForm.group_type-1] = groupData;
				}
				let options = JSON.parse(JSON.stringify(this.cardGroup[this.cardType].group_list[type-1]));
				let formData = {
					name: this.cardForm.name,
					cover: this.cardForm.cover,
					group_type: type,
					...options,
				}
				this.cardForm = JSON.parse(JSON.stringify(formData));
				this.cardGroup[this.cardType].group_type = type;
				this.$refs.cardForm1.clearValidate();
			},
			httpRequestImage(file,width=375,htight=375){
				let that = this;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 5;
				if (!istype) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileType'));
					return false;
				}
				if (!isSize) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileSize5M'));
					return false;
				}
				let imgUrl = URL.createObjectURL(file);
				that.fileInfo = file;
				that.$nextTick(() => {
					that.option.img = imgUrl;
					that.option.autoCropWidth = width;
					that.option.autoCropHeight = htight;
					that.dialogVisibleCropper = true;
				})
			},
			httpRequestImage2(file){
				let that = this;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 5;
				if (!istype) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileType'));
					return false;
				}
				if (!isSize) {
					that.$message.error(this.$t('liveplatform.errorMsg.imgFileSize5M'));
					return false;
				}
				let imgUrl = URL.createObjectURL(file);
				that.fileInfo = file;
				that.$nextTick(() => {
					that.option.img = imgUrl;
					that.option.autoCropWidth = 150;
					that.option.autoCropHeight = 300;
					that.option.fixedNumber = [1, 2];
					that.dialogVisibleCropper = true;
				})
			},
			handleCancelCropper(){
				this.option.img = '';
				this.dialogVisibleCropper = false;
			},
			handleConfirmCropper(){
				this.loadingUplaod = true;
				this.$refs.cropper.getCropBlob(async(file) =>{
					file.name = this.fileInfo.name;
					const { code, data } = await normalOSSUpload(file,'live');
					if(code==200){
						if(this.tabsActive==1){
							this.onBase64Image(data.url).then((base64)=>{
								this.cardForm.group_base64 = base64;
								this.cardForm.group_image = data.url;
							})
						}else{
							this.cardForm.cover = data.url;
							this.cardInfo.image_url = data.url;
						}
						this.dialogVisibleCropper = false;
					}else{
						this.$message.error(this.$t('liveplatform.errorMsg.upload'))
					}
					this.loadingUplaod = false;
				})
			},
			handleGroupAdd(){
				let groupData = JSON.parse(JSON.stringify(this.cardForm));
				delete groupData.name;
				delete groupData.cover;
				delete groupData.group_type;
				let option = {
					group_type: this.cardForm.group_type,
					...groupData,
				}
				this.groupList[this.cardType]=option;
			},
			base64ImgtoFile(dataurl, filename = 'file') {
				let arr = dataurl.split(',')
				let mime = arr[0].match(/:(.*?);/)[1]
				let suffix = mime.split('/')[1]
				let bstr = atob(arr[1])
				let n = bstr.length
				let u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], `${filename}.${suffix}`, {
					type: mime
				})
			},
			handleSubmitCard(){
				let that = this;
				if(that.cardForm.group_type>0){
					let groupData = JSON.parse(JSON.stringify(that.cardForm));
					delete groupData.name;
					delete groupData.cover;
					delete groupData.group_type;
					that.cardGroup[that.cardType].group_list[that.cardForm.group_type-1] = groupData;
				}
				that.$refs.cardForm1.validate((valid)=>{
					if(valid){
						let n = 0;
						let flag = 0;
						let groupList = [];
						that.cardGroup.forEach(group=>{
							if(group.group_type>0){
								let group_item = group.group_list[group.group_type-1];
								for(let key in group_item){
									if(!group_item[key]){
										flag = 1;
									}
								}
								delete group_item.group_base64;
								let item = {
									group_type: group.group_type,
									...group_item
								}
								groupList.push(item);
							}
						})
						if(groupList.length<2){
							that.$message.error(this.$t('liveplatform.errorMsg.groupMinLength'));
							return false;
						}
						if(flag==1){
							that.$message.error(this.$t('liveplatform.errorMsg.groupFormWrite'));
							return false;
						}
						let loadng = that.$loading();
						html2canvas(that.$refs.cardCanvasView, {
							useCORS: true,
							backgroundColor: null,
						}).then(async(canvas) => {
							let url = canvas.toDataURL("image/png");
							let file = that.base64ImgtoFile(url);
							const { code, data } = await normalOSSUpload(file,'live');
							if(code==200){
								let content = {
									card_type: that.tabsActive,
									card_list: groupList,
								}
								let options = {
									content: JSON.stringify(content),
									image_url: data.url,
									name: that.cardForm.name,
								}
								if(that.$route.params.id>0){
									that.onSubmitEditCard(options,loadng);
								}else{
									that.onSubmitAddCard(options,loadng);
								}
							}else{
								that.$message.error(this.$t('liveplatform.errorMsg.submit'))
							}
							that.loadingUplaod = false;
						})
					}else {
						return false;
					}
				})
			},
			handleSaveCard(){
				let that = this;
				that.$refs.cardForm2.validate((valid)=>{
					if(valid){
						let content = {
							card_type: that.tabsActive,
							card_list: [],
						}
						let loadng = that.$loading();
						let options = {
							content: JSON.stringify(content),
							image_url: that.cardForm.cover,
							name: that.cardForm.name,
						}
						if(that.$route.params.id>0){
							that.onSubmitEditCard(options,loadng);
						}else{
							that.onSubmitAddCard(options,loadng);
						}
					}else{
						return false
					}
				})
			},
			onSubmitAddCard(option,loadng){
				let that = this;
				let param = {
					...option,
					app_key: this.$store.getters.appKey
				}
				createInfoCard(param).then(() => {
					loadng.close();
					this.$message.success(this.$t('liveplatform.successMsg.add'));
					this.$router.push({name: 'LiveInformationcard'});
				}).catch(() => {
					loadng.close();
				})
			},
			onSubmitEditCard(option,loadng){
				let param = {
					...option,
					info_card_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				updeteInfoCard(param).then(() => {
					loadng.close();
					this.$message.success(this.$t('liveplatform.successMsg.edit'));
					this.$router.push({name: 'LiveInformationcard'});
				}).catch(() => {
					loadng.close();
				})
			},
			handleDeleteCard(){
				this.$confirm(this.$t('liveplatform.confirmMsg.deleteText'), this.$t('liveplatform.confirmMsg.title'), {
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let param = {
						status: 2,
						info_card_id: this.$route.params.id,
						app_key: this.$store.getters.appKey
					}
					deleteInfoCard(param).then(() => {
						this.$message.success(this.$t('liveplatform.successMsg.delete'));
						this.$router.push({name: 'LiveInformationcard'});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('liveplatform.cancelMsg.delete')
					});
				});
			}
		},
		watch: {
			"cardForm": {
				handler(newVal,oldVal){
					let that = this;
					// that.cardCanvas = newVal;
					if(newVal.group_type>0 && that.cardFlag){
						that.$nextTick(()=>{
							html2canvas(that.$refs.cardCanvas, {
								useCORS: true,
								backgroundColor: null,
							}).then((canvas) => {
								let url = canvas.toDataURL("image/png");
								that.$set(that.cardGroup[that.cardType],'group_cover',url)
							})
						})
					}
				},
				deep: true,
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page-home {
		background-color: $--live-background-default;
		padding: 20px;
		border-radius: 6px;
		.page-head {
			padding: 14px 24px;
			line-height: 35px;
			.btn-prev {
				color: #FFFFFF;
				font-size: 20px;
				font-weight: 600;
				span {
					margin-left: 12px;
				}
				i {
					font-weight: 600;
				}
			}
			.btn-del {
				color: #FFFFFF;
				font-size: 14px;
				float: right;
				i {
					margin-right: 4px;
				}
			}
		}
		.page-body {
			display: flex;
			padding: 24px 0;
			.page-preview {
				width: 375px;
				height: 812px;
				margin: 0 24px;
				position: relative;
				border-radius: 20px;
				background-color: rgba(255,255,255,.2);
				.page-canvas-hide {
					height: 0;
					overflow: hidden;
				}
				.page-canvas {
					width: 190px;
					height: 190px;
					box-sizing: border-box;
					text-align: center;
					.canvas-title {
						color: #ffe099;
						height: 38px;
						display: flex;
						line-height: 38px;
						margin-bottom: 4px;
						margin-top: 6px;
						padding: 0 14px;
						white-space: nowrap;
						.name {
							flex: 1;
							width: 0;
							margin: 0 10px;
							font-size: 22px;
							i {
								margin-right: 4px;
							}
						}
					}
					.canvas-img {
						margin-bottom: 2px;
						img {
							width: 80px;
							height: 80px;
						}
					}
					.canvas-img2 {
						width: 100%;
						height: 100%;
					}
					.canvas-text {
						color: #FFFFFF;
						font-size: 20px;
						height: 26px;
						line-height: 26px;
						margin: 0 12px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					.canvas-text.font16 {
						font-size: 16px;
					}
					.canvas-text.font18{
						font-size: 18px;
					}
				}
				.page-canvas-view {
					width: 190px;
					img {
						width: 190px;
						height: 190px;
						object-fit: cover;
					}
				}
				.preview-inner {
					top: 140px;
					left: 12px;
					position: absolute;
					.preview-wrap {
						width: 95px;
						height: 290px;
						padding: 5px;
						background-color: rgba(78,78,78,.6);
						border-radius: 6px;
						box-sizing: border-box;
						.item {
							width: 85px;
							height: 90px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px dashed #FFFFFF;
							img {
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
							.el-icon-plus {
								color: #FFFFFF;
								font-size: 20px;
							}
						}
						.item.active {
							border: 1px solid $--live-default;
						}
						.h280 {
							height: 280px;
						}
					}
				}
			}
			.page-content {
				flex: 1;
				width: 0;
				::v-deep .el-tabs {
					.el-tabs__header {
						margin-bottom: 30px;
						.el-tabs__nav-wrap {
							text-align: center;
							.el-tabs__nav-scroll {
								.el-tabs__nav {
									display: inline-block;
									float: none;
									.el-tabs__active-bar {
										background-color: $--live-tab-primary-active;
									}
									.el-tabs__item {
										color: #fff;
									}
									.el-tabs__item.is-active {
										color: $--live-tab-primary-active;
									}
								}
							}
						}
						.el-tabs__nav-wrap:after {
							display: none;
						}
					}
				}
				::v-deep .el-form {
					.el-form-item__label {
						color: #FFFFFF;
						text-align: left;
					}
				}
				::v-deep .el-input {
					max-width: 300px;
					.el-input__inner {
						color: #FFFFFF;
						border-color: $--live-background-color;
						background-color: $--live-background-color;
					}
					.el-input__inner:hover,.el-input__inner:focus {
						border-color: $--live-input-hover-1;
					}
				}
				::v-deep .el-dialog {
					background-color: $--live-background-default;
					.el-dialog__header {
						padding: 16px 20px;
						border-bottom: 1px solid $--live-gray-white-4;
						.el-dialog__title {
							color: #FFFFFF;
							font-size: 16px;
						}
						.el-dialog__headerbtn {
							.el-dialog__close {
								color: $--live-link-color-4;
							}
							&:hover {
								.el-dialog__close {
									color: #FFFFFF;
								}
							}
						}
					}
					.el-dialog__body {
						padding: 20px;
					}
					.el-dialog__footer {
						border-top: 1px solid $--live-gray-white-4;
						padding: 10px 20px;
					}
				}
				.dialog-cropper {
					height: 500px;
				}
				.long-upload {
					width: 150px;
					.upload-main {
						width: 150px;
						height: 300px;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						border-radius: 6px;
						overflow: hidden;
						background-color: $--live-gray-white-16;
						.el-icon-plus {
							color: #FFFFFF;
							font-size: 20px;
						}
						img {
							width: 100%;
							height: 100%;
						}
					}
					.upload-tip {
						width: 100%;
						bottom: 0;
						height: 28px;
						line-height: 28px;
						text-align: center;
						font-size: 14px;
						color: #FFFFFF;
						position: absolute;
						background-color: rgba(0,0,0,.5);
					}
				}
				.page-notice {
					margin-top: 16px;
					opacity: .6;
					font-size: 14px;
					color: #FFFFFF;
					line-height: 1.5715;
				}
				.template-list {
					display: flex;
					.item {
						width: 97px;
						flex-shrink: 0;
						margin-right: 30px;
						cursor: pointer;
						.pic {
							width: 97px;
							height: 112px;
							overflow: hidden;
							box-sizing: border-box;
							border-radius: 6px;
							background-color: rgba(0,0,0,.4);
							border: 1px solid transparent;
							img {
								width: 100%;
							}
						}
						.name {
							margin-top: 10px;
							font-size: 12px;
							color: #FFFFFF;
							height: 22px;
							line-height: 22px;
							text-align: center;
						}
						.name:hover {
							color: $--live-default;
						}
					}
					.item.active {
						.pic {
							border-color: $--live-default;
						}
						.name {
							color: $--live-default;
						}
					}
				}
				.page-main {
					width: 90%;
					min-width: 600px;
					margin-top: 30px;
					margin-bottom: 20px;
					border-radius: 6px;
					padding: 20px;
					background-color: $--live-gray-white-6;
					::v-deep .el-form-item {
						.el-form-item__label {
							text-align: right;
						}
					}
					::v-deep .el-button--infor {
						color: #FFFFFF;
						border-color: $--live-link-color-3;
						background-color: transparent;
					}
					::v-deep .el-button--infor:hover {
						color: $--live-default;
						border-color: $--live-default;
						background-color: transparent;
					}
					.card-uplaod {
						width: 90px;
						height: 90px;
						.upload-main {
							width: 90px;
							height: 90px;
							position: relative;
							border-radius: 6px;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: $--live-gray-white-16;
							.el-icon-plus {
								color: #FFFFFF;
								font-size: 20px;
							}
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
							.upload-tip {
								width: 100%;
								bottom: 0;
								height: 28px;
								line-height: 28px;
								text-align: center;
								font-size: 14px;
								color: #FFFFFF;
								position: absolute;
								background-color: rgba(0,0,0,.5);
							}
						}
					}
				}
			}
		}
	}
	.page-bottom {
		.el-button--danger {
			width: 100px;
			color: #FFFFFF;
			border-color: $--live-button-primary-border;
			background-color: $--live-button-primary-background;
			&:hover,&:focus{
				border-color: $--live-button-primary-hover-border;
				background-color: $--live-button-primary-hover-background;
			}
		}
	}
</style>
